<template>
  <div>
    <van-dropdown-menu active-color="#07c160" class="topp">
      <div class="let" @click.stop="back">
        <van-icon style="margin-top: 15px;" name="arrow-left" size="16" />
      </div>
      <div class="rit" @click.stop="toxinxi">
        <img class="imgs" src="../../assets/img/tongji.png" alt />
      </div>
      <van-dropdown-item :title="listname" ref="item" @close="queding">
        <van-cell
          center
          :title="item.name"
          v-for="(item,idx) in sjlist"
          :key="idx"
          @click="xuanzhe(item)"
        >
          <template #right-icon>
            <van-checkbox checked-color="#07c160" v-model="item.isOk"></van-checkbox>
          </template>
        </van-cell>
        <div style="padding: 5px 16px;">
          <van-button type="primary" block plain @click="onConfirm">确认</van-button>
        </div>
      </van-dropdown-item>
    </van-dropdown-menu>
    <div class="hed_lis">
      <div class="heji_h1">
        <div class="hj_a">合计：{{list.unOffCount}}</div>
        <div class="hj_a">
          其中 新客：
          <span style="color: red;">{{list.unOffNewCount}}</span>
        </div>
        <div class="hj_a">
          老客：
          <span style="color: #3ac29f;">{{list.unOffOldCount}}</span>
        </div>
      </div>
      <div class="hhcc" @click="qiehuan(0)">
        销售员业绩
        <div class="hua" v-if="active == '0'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(1)">
        已核销用户
        <div class="hua" v-if="active == '1'"></div>
      </div>
      <div class="hhcc" @click="qiehuan(2)">
        未核销用户
        <div class="hua" v-if="active == '2'"></div>
      </div>
    </div>
    <van-row class="biaotou dingwei_a" v-if="active == '0'">
      <van-col span="2">#</van-col>
      <van-col span="5">销售员</van-col>
      <van-col span="4">总销量</van-col>
      <van-col span="4">已核销</van-col>
      <van-col span="3">直推</van-col>
      <van-col span="6">转介绍+电销</van-col>
    </van-row>
    <div v-if="active == '0'" style="overflow: hidden;background: #fff;padding-top:145px">
      <van-list
        v-model="xsyloading"
        :finished="xsyfinished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="xsyonLoads"
        :offset="10"
      >
        <van-row class="biaotou2" v-for="(item,ides) in userList" :key="ides+'a'">
          <van-col span="2">{{ides+1}}</van-col>
          <van-col span="5">{{item.name}}</van-col>
          <van-col span="4">{{item.totalCount}}</van-col>
          <van-col span="4">{{item.offCount}}</van-col>
          <van-col span="3">{{item.scount}}</van-col>
          <van-col span="6">{{item.ssCount}}</van-col>
        </van-row>
      </van-list>
    </div>
    <div v-if="active == '1'" class="dingwei_b">
      <!-- <div class="he_shuju2">已核销的客户</div> -->
      <van-row class="biaotou">
        <van-col span="2">#</van-col>
        <van-col span="7">联系方式</van-col>
        <van-col span="5">详细信息</van-col>
        <van-col span="6">购买时间</van-col>
        <van-col span="4">属性</van-col>
      </van-row>
    </div>
    <div v-if="active == '1'" style="overflow: hidden;background: #fff;padding-top:145px">
      <van-list
        v-model="yhxloading"
        :finished="yhxfinished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="yhxonLoads"
        :offset="10"
      >
        <van-row class="biaotou2" v-for="(item,idexs) in offCustomerList" :key="idexs">
          <van-col span="2">{{idexs+1}}</van-col>
          <van-col span="7">{{item.phone}}</van-col>
          <van-col span="5">{{item.carNo}}</van-col>
          <van-col span="6">{{item.bizDate?item.bizDate.substr(5,11):''}}</van-col>
          <van-col span="4">{{item.orderNumber}}</van-col>
        </van-row>
      </van-list>
    </div>
    <div v-if="active == '2'" class="dingwei_b">
      <!-- <div class="he_shuju2">未核销的客户</div> -->

      <van-row class="biaotou">
        <van-col span="2">#</van-col>
        <van-col span="2">客</van-col>
        <van-col span="5">车牌号码</van-col>
        <van-col span="6">联系方式</van-col>
        <van-col span="6">购买时间</van-col>
        <van-col span="3">属性</van-col>
      </van-row>
    </div>
    <div v-if="active == '2'" style="overflow: hidden;background: #fff;padding-top:145px">
      <van-list
        v-model="whxloading"
        :finished="whxfinished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="whxonLoads"
        :offset="10"
      >
        <van-row class="biaotou2" v-for="(item,idex) in unOffCustomerList" :key="idex">
          <van-col span="2">{{idex+1}}</van-col>
          <van-col span="2">
            <span v-if="item.newCar ==1" style="color: red;">新</span>
            <span v-else>&#12288;</span>
          </van-col>

          <van-col span="5">{{item.carNo}}</van-col>
          <van-col span="6">{{item.phone}}</van-col>
          <van-col span="6">{{item.bizDate?item.bizDate.substr(5,11):''}}</van-col>
          <van-col span="3" style="color: red;" v-if="item.agentBuy == 1" @click="todaiban(item)">代办</van-col>
          <van-col span="3" v-else>{{item.orderNumber}}</van-col>
        </van-row>
      </van-list>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";

export default {
  data() {
    return {
      gid: "",
      imgs: "../../assets/img/tongji.png",
      aid: this.$route.query.aid,
      uid: "",
      sjlist: [],
      listname: "",
      active: 0,
      gids: "",
      //销售员数据
      xsypage: 0,
      xsysize: 25,
      xsyloading: false,
      xsyfinished: false,
      xsytotal: 0,
      userList: [],
      //未核销数据
      whxpage: 0,
      whxsize: 25,
      whxloading: false,
      whxfinished: false,
      whxtotal: 0,
      unOffCustomerList: [],
      //已核销数据
      yhxpage: 0,
      yhxsize: 25,
      yhxloading: false,
      yhxfinished: false,
      yhxtotal: 0,
      offCustomerList: [],
      list: {}
    };
  },
  created() {
    this.tjlist();
  },
  methods: {
    //商家list
    tjlist() {
      userModel.tongjilist({ aid: this.aid }).then(res => {
        if (res.code == 200) {
          this.sjlist = res.data;
          var gids = "";
          this.sjlist.forEach(item => {
            if (item.isOk == true) {
              gids += item.id + ",";
            }
          });
          gids = gids.substring(0, gids.length - 1);
          this.gids = gids;
          this.listname = res.data[0].name + " 等共" + res.data.length + "店";
          this.xsytongji();
          this.weihexiaoshu();
          this.yhxtongji();
          this.whxtongji();
        }
      });
    },
    todaiban(item) {
      this.$router.push({
        path: "commission_xq",
        query: {
          aid: sessionStorage.getItem("aid"),
          carNo: item.carNo
        }
      });
    },
    weihexiaoshu() {
      var data = {
        aid: this.aid,
        gids: this.gids
      };
      userModel.offCountsl(data).then(res => {
        if (res.code == 200) {
          this.list = res.data;
          console.log(this.list);
        }
      });
    },
    xsyonLoads() {
      this.xsypage++;
      this.xsytongji();
    },
    yhxonLoads() {
      this.yhxpage++;
      this.yhxtongji();
    },
    whxonLoads() {
      this.whxpage++;
      this.whxtongji();
    },
    //未核销list
    whxtongji() {
      var data = {
        aid: this.aid,
        gids: this.gids,
        page: this.whxpage,
        size: this.whxsize
      };
      userModel.weioff(data).then(res => {
        if (res.code == 200) {
          console.log(res);
          if (res.data) {
            let rows = res.data;
            this.whxloading = false;
            this.whxtotal = res.pageInfo.total;
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.whxfinished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.unOffCustomerList = this.unOffCustomerList.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.unOffCustomerList.length >= this.whxtotal) {
              this.whxfinished = true;
            }
          } else {
            this.unOffCustomerList = [];
          }
        }
      });
    },
    //已核销list
    yhxtongji() {
      var data = {
        aid: this.aid,
        gids: this.gids,
        page: this.yhxpage,
        size: this.yhxsize
      };
      userModel.hexiaooff(data).then(res => {
        if (res.code == 200) {
          console.log(res);
          if (res.data) {
            let rows = res.data;
            this.yhxloading = false;
            this.yhxtotal = res.pageInfo.total;
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.yhxfinished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.offCustomerList = this.offCustomerList.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.offCustomerList.length >= this.yhxtotal) {
              this.yhxfinished = true;
            }
          } else {
            this.offCustomerList = [];
          }
        }
      });
    },
    //销售员list
    xsytongji() {
      var data = {
        aid: this.aid,
        gids: this.gids,
        page: this.xsypage,
        size: this.xsysize
      };
      userModel.ersale(data).then(res => {
        if (res.code == 200) {
          console.log(res);
          if (res.data) {
            let rows = res.data;
            this.xsyloading = false;
            this.xsytotal = res.pageInfo.total;
            if (rows == null || rows.length === 0) {
              // 加载结束
              this.xsyfinished = true;
              return;
            }
            // 将新数据与老数据进行合并
            this.userList = this.userList.concat(rows);
            //如果列表数据条数>=总条数，不再触发滚动加载
            if (this.userList.length >= this.xsytotal) {
              this.xsyfinished = true;
            }
          } else {
            this.userList = [];
          }
        }
      });
    },
    qiehuan(row) {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      //实现滚动效果
      //   const timeTop = setInterval(() => {
      //     document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
      //     if (top <= 0) {
      //       clearInterval(timeTop);
      //     }
      //   }, 10);
      document.body.scrollTop = 0;

      if (document.body.scrollTop == 0) {
        this.active = row;
      }
    },
    queding() {
      var shangjia = [];
      this.xsypage = 0;
      this.xsyloading = false;
      this.xsyfinished = false;
      this.whxpage = 0;
      this.whxloading = false;
      this.whxfinished = false;
      this.yhxpage = 0;
      this.yhxloading = false;
      this.yhxfinished = false;
      this.userList = [];
      this.unOffCustomerList = [];
      this.offCustomerList = [];
      var gids = "";
      this.sjlist.forEach(item => {
        if (item.isOk == true) {
          shangjia.push(item);
          gids += item.id + ",";
        }
      });
      gids = gids.substring(0, gids.length - 1);
      this.gids = gids;
      console.log(shangjia.length);
      if (shangjia != 0) {
        this.listname = shangjia[0].name + " 等共" + shangjia.length + "店";
      }

      this.xsytongji();
      this.weihexiaoshu();
      this.yhxtongji();
      this.whxtongji();
    },
    xuanzhe(item) {
      console.log(item);
      item.isOk = !item.isOk;
    },
    onConfirm() {
      this.$refs.item.toggle();
    },
    toxinxi() {
      this.$router.push({
        path: "statistics",
        query: {
          aid: this.aid
        }
      });
    },
    back() {
      this.$router.go(-1);
    }
  }
};
</script>
<style  lang="less" scoped>
.che_h2 {
  width: 50%;
}
.heji_h1 {
  border-top: 1px solid #f5f5f5;
  border-bottom: 1px solid #f5f5f5;
  height: 25px;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  background: #fff;
}
.hj_a {
  float: left;
  width: 33%;
  text-align: center;
}
.shenqin {
  border-top: 1px solid #f5f5f5;
  font-size: 14px;
  color: #666;
  line-height: 20px;
  padding-top: 8px;
}

.c_h1 {
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 2px;
  width: 50%;
  height: 25px;
  margin-left: 55px;
}
.van-dropdown-menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 999;
  background: #fff;
}
.h1 {
  height: 45px;
  border-bottom: 1px solid #ccc;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 99;
  line-height: 45px;
}
.van-dropdown-menu__title--active {
  color: #07c160;
}
.xuanzheqi {
  border: 1px solid #dcdfe6;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  border-radius: 4px;
  width: 95%;
}

.mendianh {
  width: 30%;
  overflow: hidden;
}

.xiabiao {
  position: absolute;
  right: 0;
  top: 0;
  color: #666;
  margin-top: 15px;
  margin-right: 5px;
}
.topp {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
}
.biaoti {
  padding: 10px 12px;
  font-size: 14px;
  text-align: center;
  line-height: 20px;
}

.red {
  color: red;
  font-weight: bold;
  z-index: 999;
}

.shuju {
  padding: 15px 12px;
  font-size: 14px;
}

.van-col {
  text-align: center;
  border-right: 1px solid #f1f1f1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  padding: 0 0px;
}
.imgs {
  width: 24px;
  margin-top: 11px;
}
.biaotou {
  //   padding-top:75px;
  background: #fff;
  z-index: 99;
  border: 1px solid #f1f1f1;
  font-size: 14px;
  line-height: 30px;
  color: #000;
}
.dingwei_a {
  position: fixed;
  top: 113px;
  left: 0;
  width: 100%;
}
.dingwei_b {
  position: fixed;
  top: 113px;
  left: 0;
  width: 100%;
}
.biaotou2 {
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #f1f1f1;
  border-top: none;
  color: #666;
}
.let {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
}
.rit {
  width: 40px;
  height: 100%;
  text-align: center;
  position: absolute;
  right: 0;
  top: 0;
}
.he_shuju {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  font-weight: bold;
}

.he_shuju2 {
  margin-top: 15px;
  border-top: 1px solid #ccc;
  line-height: 40px;
  overflow: hidden;
  padding: 0 12px;
  font-size: 14px;
  text-align: center;
  color: #000;
  font-weight: bold;
}
.hed_lis {
  position: fixed;
  top: 48px;
  width: 100%;
  /* position: relative; */
  // height: 40px;
  background: #fff;
  z-index: 99;
  border-bottom: 1px solid #ebedf0;
}

.hhcc {
  float: left;
  font-size: 13px;
  color: #646566;
  width: 33.3%;
  text-align: center;
  line-height: 40px;
  position: relative;
  overflow: hidden;
}
.ord_lis {
  overflow: hidden;
  height: 100%;
  background: #ebedf0;
}
.hua {
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-bottom: 5px;
  width: 46px;
  text-align: center;
  margin-left: -23px;
  height: 3px;
  z-index: 1;
  border-radius: 1.5px;
  background-color: #0d906e;
}
</style>
<style>
.van-dropdown-menu__bar {
  box-shadow: none;
}
</style>